
body {
  margin: 0;
    font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: black;
  color: white;
}
.login-container{
	width: 100%;
}

.login-header{
	width: 100%;
	height: 600px;
	background-color: #bd2241;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
}

.login-logo{
	font-size: 40px;
	font-weight: 900;
	flex-grow: 1;
}

.login-navbar{
	width: 60%;
	display: flex;
}



.login-button{
	cursor: pointer;
	margin: 10px;
	font-size: 25px;
}

.lb1{
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 7px;
	background-color: white;
	border-radius: 25px;
	color: black;
	width: 100px;
	text-align: center;
}

.login-description{
	margin-top: 100px;
	text-align: center;
}

.ld1{
	font-size: 60px;
	font-weight: bold;
}

.ld2{
	font-size: 30px;
}

.login-info-buttons{
	display: flex;
	justify-content: center;
}

.login-info-button{
	margin: 20px;
	font-size: 25px;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 25px;
	cursor: pointer;
	border: 2px solid white;
	padding-top: 10px;
	padding-bottom: 10px;
}

.lib2{
	background-color: white;
	color: black;
}
.lib2:hover{
	background-color: #bd2241;
	color: white;
}

.lib1:hover{
	background-color: #d1b513;
	border: 1px solid #d1b513;
	color: black;
}

.login-h{
	font-weight: 400;
	font-size: 30px;
	margin-bottom: 10px;
}

.login-main{
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

}

.login-block{
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 30px;
}

.login-img{
	border-radius: 25px;
}

.login-main-descr{
	width: 50rem;
	font-size: 22px;
	margin-top: 20px;
}

.login-line{
	margin-top: 30px;
	margin-bottom: 30px;
	width: 30rem;
	height: 1px;
	background-color: lightgrey;
}

.login-sell{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-offer{
	margin-top: 30px;
	width: 350px;
	height: 650px;
	border-radius: 25px;
	padding: 15px;
	border: 1px solid #bd2241;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.login-block{

}

.buy-button{
	margin-top: 20px !important;
	padding-top: 10px;
	padding-bottom: 10px;
	cursor: pointer;
	margin: 10px;
	font-size: 25px;
	border: 1px solid black;
	width: 200px;
	border-radius: 25px;
	background-color: #bd2241;
	color: white;
}

.buy-button:hover{
	background-color: white;
	color: black;
}

.login-footer{
	background-color: black;
	height: 50px;
	padding: 10px;
	font-size: 22px;
	color: white;
}

.login-email{
	padding: 7px;
	font-size: 20px;
	width: 250px;
	border-radius: 10px;
}

/*FORMS*/



.login-form-container, .signup-form-container{
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
}

.login-form, .signup-form{
	color: black;
	background-color: #bd2241;
	width: 500px;
	border-radius: 25px;
	height: 300px;
	position: absolute;
	z-index: 1000000;
	left: 50%;
   top: 50%;
   transform: translate(-50%,-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}


.login-email{
	padding: 10px;
	margin:20px;
}

.submit-login-button{
	width: 200px;
	text-align: center;
	background-color: white;
	padding: 5px;
	font-size: 25px;
	border-radius: 25px;
	cursor: pointer;
}


.signup-form{
	height: 500px;
}

.login-close-form{
	color: white;
	font-size: 25px;
	margin-top: -30px;
	margin-left: 430px;
	cursor: pointer;
}

.email-password-not-match{
	font-size: 15px;
	color: #ff6666;
	margin-top: -10px;
	margin-bottom: 10px;
}

.login-lg-buttons{
	display: flex;
}


@media only screen and (max-width: 820px) {
  	.img-type-one{
	width: 80% !important;
	}
	.img-type-two{
		width: 85% !important;
	}
	.img-type-three{
		width: 60% !important;
	}
	.login-h{
		width: 90%  !important;

		font-size: 20px !important;
	}
	.login-main-descr{
		width: 90%  !important;
		font-size: 20px !important;
	}
	.ld1{
		font-size: 50px;
	}
	.ld2{
		font-size: 22px !important;
	}
	.login-block{
		padding-left:0 !important;
		padding-right:0 !important;

	}
	.login-sell-message{
	width: 90%  !important;
}
}

.login-features-list{
	margin-top: 10px;
	font-size: 18px;
	text-align: left;
	display: flex;
	flex-direction: column;
}

.login-features-list > span{
	margin: 3px;
}